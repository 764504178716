import React from 'react'

import GeneralPageLayout from '../components/Layouts/GeneralPage'
import TextImgSplit from '../components/Layouts/TextImgSplit/TextImgSplit'
import MainTextBlock from '../components/UI/MainTextBlock/MainTextBlock'
import SectionContainer from './../components/UI/Containers/SectionContainer'
import SectionHeader from './../components/UI/SectionHeader/SectionHeader'
import SplitTwoToneContainer from '../components/UI/Containers/SplitTwoToneContainer'
import BannerCallout from '../components/Callouts/BannerCallout/BannerCallout'

import './../styles/pageSpecific/ethicsSustainability.scss'

import EthicsSustainabilityGraphic from './../images/ethics-sustainability.jpg'
import SustainmentList from '../components/UI/Sustainment/SustainmentList'
import SimpleButton from '../components/UI/Buttons/SimpleButton/SimpleButton'

import CodeConduct from './../pdfs/Precise_Supplier_Code_of_Conduct_rev2.pdf'

const EthicsSustainabilityPage = () => {
  const pageInfo = {
    pageType: 'interior',
    title1: 'Ethics &',
    title2: 'Sustainability',
    breadcrumbs: ['Who We Are'],
    ctaButton: false,
    ctaButtonData: {
      text: '',
      url: '',
    },
  }

  return (
    <GeneralPageLayout pageData={pageInfo}>
      <div className="content">
        <SectionContainer
          id="introEthics"
          color="white"
          type="pressedEdgeRight"
        >
          <SectionHeader color="navy" alignment="left" size="med">
            Ethics In <span>Everything We Do</span>
          </SectionHeader>
          <TextImgSplit
            imageData={EthicsSustainabilityGraphic}
            altInfo="diverse business professionals shaking hands over desk"
          >
            <MainTextBlock>
              <p>
                Our philosophy has been, and always will be, to sustain the
                highest possible business ethics in the pursuit and performance
                of our business. By codifying our commitment to integrity and
                values, we set the course for focus on culture and ethics that
                is growing stronger by day.
              </p>
              <p>
                Through our commitment to the highest standards of business
                ethics and integrity, we hold all of our employees accountable
                for ethical behavior established by a common code of business
                ethics and compliance known as our Ethics Handbook. We conduct
                all business dealings with the highest degree of honesty and
                integrity, and partner with organizations that are committed to
                the same ethical behavior.
              </p>
            </MainTextBlock>
          </TextImgSplit>
        </SectionContainer>

        <SplitTwoToneContainer id="" classes={['hugMiddleLine', 'narrowWidth']}>
          <section>
            <h3>
              <span>Our values are not simply statements or aspirations </span>
            </h3>
          </section>
          <section>
            <h3>—they are commitments that drive us in everything we do.</h3>
          </section>
        </SplitTwoToneContainer>

        <SectionContainer
          id="sustaibilityStatement"
          color="white"
          type="fullWidth"
        >
          <SectionHeader color="navy" alignment="center" size="med" trim={true}>
            Embracing Sustainability <span>In Our Workplace</span>
          </SectionHeader>
          <p className={`displayParagraph`}>
            Precise incorporates sustainable practices that integrate our
            environmental, social, and economic activities with our goal of
            becoming an “industry leader.” As we pursue sustainability with our
            communities and partners, we are making a fundamental transition
            from an economy that is against nature to one that participates with
            nature. In the workplace, we’ve targeted several key areas to build
            a sustainable organization and significantly reduce our carbon
            footprint. Among our activities we provide a flexible work
            environment and basing employees closer to their homes,
            telecommuting, co-location, and remote worksite
            arrangements–reducing our commute times and carbon footprint while
            improving quality of life.{' '}
          </p>
          <SustainmentList />
          <div className={`hexagon red hexagonRedMarker`}>
            <div className="hexTop"></div>
            <div className="hexBottom"></div>
          </div>
        </SectionContainer>

        {/* Suppliers */}
        <SectionContainer id="suppliers" color="gray" type="contained">
          <SectionHeader
            color="navy"
            alignment="center"
            size="med"
            trim="true"
            boldBreak={true}
          >
            Sharing Our Ethical Standards <span> With Our Suppliers</span>
          </SectionHeader>
          <section className="inlineChildren infoSection">
            <section className="textContent">
              <p>
                Commensurate with the size and nature of their businesses, we
                expect our suppliers to have management and business systems to
                support compliance with all applicable laws, regulations and the
                highest possible business ethics. Expect suppliers to comply
                with FAR 52.203-13 as appropriate.
              </p>
              <section className="inlineChildren_mobile buttonWrap">
                <SimpleButton
                  icon={`codicon:package`}
                  text="Becoming Our Supplier"
                  url="/suppliers/"
                  type="solidRed"
                />
                <SimpleButton
                  location={`external`}
                  type={`gray`}
                  url={CodeConduct}
                  icon={`bi:cloud-download`}
                  text={`Supplier Code of Conduct`}
                  rel="noreferrer"
                  target="_blank"
                />
              </section>
            </section>
          </section>
          <section
            id="suppliersContact"
            className="inlineChildren_column contactsSection"
          >
            <section className="textContent ">
              <p>
                If a supplier has a concern regarding the expectations of
                suspects a violation of the Precise Code of Business Ethics and
                Conduct, the Supplier Code of Conduct or any law or regulation
                applicable to Precise Systems, email{' '}
                <a
                  href={`mailto:Compliance@GoPrecise.com`}
                  className="inlineLink"
                >
                  {' '}
                  Compliance@GoPrecise.com
                </a>{' '}
                or make an anonymous report by calling{' '}
                <a href={`tel:+1-805-392-5105`} className="inlineLink">
                  1-805-392-5105
                </a>
                .{' '}
              </p>
            </section>
            <section className="inlineChildren_mobile buttonWrap">
              <SimpleButton
                icon="codicon:mail"
                type="gray"
                url={`mailto:Compliance@GoPrecise.com`}
                text="Email Precise"
                location="external"
              />
              <SimpleButton
                icon="clarity:phone-handset-solid"
                type="gray"
                url={`tel:+1-805-392-5105`}
                text="Call Anonymously"
                location="external"
              />
            </section>
          </section>
        </SectionContainer>

        {/* CallOut */}
        <BannerCallout color="navy" />
      </div>
    </GeneralPageLayout>
  )
}

export default EthicsSustainabilityPage

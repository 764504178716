import React from "react"
import * as styles from './BannerCallout.module.scss'
import SectionContainer from "../../UI/Containers/SectionContainer"
import CallToActionButton from "../../UI/Buttons/CallToActionButton"


const BannerCallout = ({color}) => {
    // color: colored or navy

    const ButtonData ={
        text: 'Connect Now',
        url: 'mailto:Precise@GoPrecise.com',
        icon:'mdi:email-send-outline',
        location: 'external'
      }

    return(
        <SectionContainer color='white' type='contained'>
            <article className={` ${styles.callout} ${styles[`${color}`]}`}>
            <section className={` ${styles.callout__wrap} `}>
                <section className={` ${styles.callout__text} `}>
                        <h3>
                            Want to work with Precise?
                            <span>Lets <em>Build Together</em></span>
                        </h3>
                </section>
                <section className={` ${styles.callout__btn} `}>
                        <CallToActionButton type={color === 'navy' ? 'white' : 'colored'} buttonData={ButtonData}/>
                </section>
                </section>
            </article>
        </SectionContainer>
    )
}

export default BannerCallout
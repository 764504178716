import React from 'react'
import * as styles from './TextImgSplit.module.scss'


const TextImgSplit = ({children, imageData, altInfo}) => {
    return(
        <article className={styles.split__container}>
            <section className={styles.split__imageWrap}>
                <img src={imageData} alt={altInfo}/>
            </section>
            <section className={styles.split__textWrap}>
                {children}
            </section>
        </article>
     
    )
}

export default TextImgSplit